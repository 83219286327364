import React from 'react'
import Plot from 'react-plotly.js'
import { useState, useEffect } from 'react';

const LinePlot = () => {

  const [monthlySales, updateMSales] = useState({});

  useEffect(() =>{
    getMSales();
  }, []);

  const mSalesUrl = "https://xmnd4tpb48.us-west-2.awsapprunner.com/api/date_tools?year=2017&operation=mean&type=monthly"

  const getMSales = async () => {
    const response = await fetch(mSalesUrl);
    const jsonData = await response.json();  
    updateMSales(jsonData);
  };

  let x;
  let y;
  try{
    const cols = Object.keys(monthlySales[0]);
    x = monthlySales.map(d => d[cols[0]])
    y = monthlySales.map(d => d[cols[1]])
  } catch { console.log('loading')}
  return (
    <React.Fragment>
        <Plot 
            data = {[
                {
                    x: x,
                    y: y,
                    type: 'scatter', 
                    mode: 'lines+markers',
                    marker: {color: 'white'},
                }
            ]}

            layout={ {autosize:true,
                      title: {
                        text:'Average Sale Value Per Month',
                        font:{
                            color:'#ffffff'
                        }
                        },
                      plot_bgcolor:"rgba(0,0,0,0)",
                      paper_bgcolor:"rgba(0,0,0,0)",
                      xaxis:{
                        gridcolor: '#ababab',
                        tickcolor: '#ababab',
                        tickfont:{
                            color:'#ababab'
                        }
                      },
                      yaxis:{
                        gridcolor: '#ababab',
                        tickcolor: '#ababab',
                        tickfont:{
                            color:'#ababab'
                        }
                      },
                      automargin:true }
                    }

            useResizeHandler= {true}

            style={{width: "100%", height: "70vh",
                    minHeight:'40vh', maxHeight:'70vh'}} 
        />

    </React.Fragment>
  )
}

export default LinePlot