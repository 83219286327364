import React from 'react'
import { Grid, Paper, Table, TableHead, TableRow, TableCell} from "@mui/material";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Table8 from '../dashboard_components/Table8'
import PlotBlock4 from '../dashboard_components/PlotBlock4';
import PlotBlock6 from '../dashboard_components/PlotBlock6';
import Checkbox from '@mui/material/Checkbox';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#3d3d3d',
    height:"100%",
    ...theme.typography.body2,
    padding: 3,
    textAlign: 'center',
    color: '#ffffff',
  }));

const latestPurch = [{'test':2,'n':3}]

let state = {
    RB: true,
    HB: true,
    LB: true,
    ST: true
  }

let fetchUrl = 'https://xmnd4tpb48.us-west-2.awsapprunner.com/api/purchases?kind=TB,AB,GB,OB,HTB,HAB,HGB,LTB,LAB,LGB,IK'
let baseUrl = 'https://xmnd4tpb48.us-west-2.awsapprunner.com/api/purchases?kind='
let baseData = [{'test':2, 'n':3}];
// const getBaseData = async () => {
//     const response = await fetch(fetchUrl);
//     const jsonData = await response.json();
//     baseData = jsonData;
//   };


const Purchases = () => {

  const [rbChecked, setRbChecked] = React.useState(true);
  const [hbChecked, setHbChecked] = React.useState(true);
  const [lbChecked, setLbChecked] = React.useState(true);
  const [stChecked, setStChecked] = React.useState(true);
  const [data, updateData] = React.useState([{'provider':2, 'total':3}]);

  const getData = async () => {
    const response = await fetch(fetchUrl);
    const jsonData = await response.json();
    updateData(jsonData);
  };

  const handleRb = (event) => {
    setRbChecked(event.target.checked)
    state.RB = event.target.checked
    fetchUrl = baseUrl + updateBaseStr(state, '')
    getData()
  }
  const handleHb = (event) => {
    setHbChecked(event.target.checked)
    state.HB = event.target.checked
    fetchUrl = baseUrl + updateBaseStr(state, '')
    getData()
  }
  const handleLb = (event) => {
    setLbChecked(event.target.checked)
    state.LB = event.target.checked
    fetchUrl = baseUrl + updateBaseStr(state, '')
    getData()
  }
  const handleSt = (event) => {
    setStChecked(event.target.checked)
    state.ST = event.target.checked
    fetchUrl = baseUrl + updateBaseStr(state, '')
    getData()
    console.log(data)
  }

  function updateBaseStr(state, base_str){
    if (state.RB === false & state.HB == false & state.LB === false & state.ST === false){
        return 'TB,AB,GB,OB,HTB,HAB,HGB,LTB,LAB,LGB,IK'
    }
    else {
        if (state.RB === true){
            base_str = base_str + 'TB,AB,GB,OB,'
        }
        if (state.LB === true){
            base_str = base_str + 'LTB,LAB,LGB,'
        }     
        if (state.HB === true){
            base_str = base_str + 'HTB,HAB,HGB,'
        }  
        if (state.ST === true){
            base_str = base_str + 'IK,'
        }
        while(base_str.charAt(base_str.length-1)==',') {
            base_str = base_str.substring(0,base_str.length-1);
        }       
    }
    return base_str
  }


  return (
    <React.Fragment>
        <Grid container spacing={2} sx={{ pr: 2, pl: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
            <Grid item xs={12} md={3} sx={{ display: 'grid' }}>
                <Item>
                    <Checkbox 
                        sx={{color: '#ffffff', '&.Mui-checked':{color:'#ffffff'}}}
                        onChange={handleRb}
                        checked={rbChecked}>
                    </Checkbox>
                    Regular Bottle
                </Item>
            </Grid>
            <Grid item xs={12} md={3} sx={{ display: 'grid' }}>
                <Item>
                    <Checkbox sx={{color: '#ffffff', '&.Mui-checked':{color:'#ffffff'}}}
                            onChange={handleHb}
                            checked={hbChecked}>
                    </Checkbox>
                    High Density Bottles
                </Item>
            </Grid>
            <Grid item xs={12} md={3} sx={{ display: 'grid' }}>
                <Item>
                    <Checkbox sx={{color: '#ffffff', '&.Mui-checked':{color:'#ffffff'}}}
                            onChange={handleLb}
                            checked={lbChecked}>
                    </Checkbox>
                    Low Density Bottles
                </Item>
            </Grid>
            <Grid item xs={12} md={3} sx={{ display: 'grid' }}>
                <Item>
                    <Checkbox sx={{color: '#ffffff', '&.Mui-checked':{color:'#ffffff'}}}
                            onChange={handleSt}
                            checked={stChecked}>
                    </Checkbox>
                    Ink
                </Item>
            </Grid>
        </Grid>
        <br />
        <Grid container spacing={2} sx={{ pr: 2, pl: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
            <Table8 data={data}/>
            <PlotBlock4 title="Top Providers" type="pie" data={data}/>
        </Grid>
        <br />
        <Grid container spacing={2} sx={{ pr: 2, pl: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
            <PlotBlock6 type="bar"/>
            <PlotBlock6 type="bar"/>
        </Grid>
    </React.Fragment>
  )
}

export default Purchases
