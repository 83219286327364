import React from 'react'
import { AppBar, Toolbar, Box, Typography, Tabs, Tab } from '@mui/material'
import DrawerC from './DrawerC';
import { Link } from 'react-router-dom';
import { useMediaQuery , useTheme } from '@mui/material';

const pages = ['sales', 'performance', 'purchases'];

const Navbar = () => {
  const [value, setValue] = React.useState('sales');
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'))
  if (largeScreen){
    return (
      <React.Fragment>
        <AppBar sx={{position:'sticky', mb:3}}>
          <Toolbar sx={{
                backgroundColor:'#1d1d1d',
                color:'#ffffff',
                pr:0
              }}
          >
            <Box
                component="img"
                sx={{
                height: 50,
                mr:5
                }}
                alt="Your logo."
                src='/unbxd_logo.png'
            />
            <Tabs textColor='inherit' 
                  value={value} 
                  sx ={{mr:'auto'}}
                  onChange={(e, value) => setValue(value)}>
                <Tab label='Sales' to={`/demo/sales`} component={Link} />
                <Tab label='Performance' to={`/demo/${pages[1]}`} component={Link}/>
                <Tab label='Purchases' to={`/demo/${pages[2]}`} component={Link}/>
            </Tabs>
            <Typography
                color="inherit"
                noWrap
                sx={{textAlign:'center'}}
              >
                Logged in as <b>chnnxyz-dev</b>
                <br />
                Log out
            </Typography>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    )
  }
  else{
    return(
      <React.Fragment>
      <AppBar sx={{position:'sticky', mb:3}}>
        <Toolbar sx={{
              backgroundColor:'#1d1d1d',
              color:'#ffffff',
              margin:'0px,0px,0px,0px',
              pr:0
            }}
        >
          <DrawerC />
          <Box
              component="img"
              sx={{
              height: 50,
              ml:'auto',
              mr:'auto',
              display:'flex'
              }}
              alt="Your logo."
              src='/unbxd_logo.png'
          />
        </Toolbar>
      </AppBar>
    </React.Fragment>
    )
  }
}

export default Navbar
