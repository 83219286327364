import React from 'react'
import LinePlot from './LinePlot'
import BarPlot from './BarPlot'
import { Grid, Paper, Table, TableHead, TableRow, TableCell} from "@mui/material";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import PiePlot from './PiePlot';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#3d3d3d',
    height:"100%",
    ...theme.typography.body2,
    padding: 3,
    textAlign: 'center',
    color: '#ffffff',
  }));

const PlotBlock6 = (props) => {
  if (props.type === 'line'){
    return (
        <Grid item xs={12} md={6}>
          <Item>
            <LinePlot />
          </Item>
        </Grid>
    )
  } else if(props.type === 'bar'){
    return(
      <Grid item xs={12} md={6}>
        <Item>
          <BarPlot data={props.data}/>
        </Item>
      </Grid>
    )
  } else if(props.type ==='pie'){
    return(
      <Grid item xs={12} md={6}>
        <Item>
          <PiePlot title={props.title} data={props.data}/>
        </Item>
      </Grid>
    )
  }
}

export default PlotBlock6