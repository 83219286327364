import PlotBlock from '../dashboard_components/PlotBlock'
import React from 'react'
import { Grid, Paper, Table, TableHead, TableRow, TableCell} from "@mui/material";
import Kpi from "../dashboard_components/Kpi";
import Table4 from '../dashboard_components/Table4';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import IndicatorBlock from '../dashboard_components/IndicatorBlock';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#3d3d3d',
    height:"100%",
    ...theme.typography.body2,
    padding: 3,
    textAlign: 'center',
    color: '#ffffff',
  }));

const Expenses = () => {
  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{pr:2, pl:2, gridTemplateColumns:'repeat(1, 1fr)'}}>
        <PlotBlock type='pie' title='Expenses Breakdown'/>
        <Grid item xs={12} md={4} sx={{display:'grid'}}>
      <Grid container spacing={2}>
        <Kpi name="Total Sales" value='$28328.22' />
        <Kpi name="Forecasted Sales in 30 days" value='$2643.82' />
        <Kpi name="Average Daily Sales" value='$800.82' />
      </Grid>
    </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Expenses