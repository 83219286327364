export const Button = ({ whereTo }) => {
  let title ='blank'
  let target = '#'

  if (whereTo === 'demo'){
      title = 'Ver un demo de nuestros tableros';
      target = '/demo'
  } else if (whereTo === 'contact') {
      title = 'Contáctanos';
      target = 'mailto:contacto@blkbxai.com'
  } else {
      title = 'blank';
  }
  return (
    <a href={target} target='_'>
    <button>{title}</button>
    </a>
  )
}

export default Button
