import Demo from './pages/Demo'
import Home from './pages/Home'
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {


  return(
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/demo" element={<Demo  where='sales'/>} />
          <Route path="/demo/sales" element={<Demo where='sales'/>} />
          <Route path="/demo/performance" element={<Demo where = 'performance'/>} />
          <Route path="/demo/expenses" element={<Demo where = 'expenses'/>} />
          <Route path="/demo/purchases" element={<Demo where = 'purchases'/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
