import { Grid, Paper, Table, TableHead, TableRow, TableCell} from "@mui/material";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#3d3d3d',
    height:"100%",
    ...theme.typography.body2,
    padding: 3,
    textAlign: 'center',
    color: '#ffffff',
  }));



const Table8 = (props) => {
  const headers = Object.keys(props.data[0])
  const rows = props.data
  return (
    <Grid item xs={12} md={8}>
    <Item>
    <h3>{props.title}</h3>
    <Table size="small"> 
        <TableHead> 
            {
                headers.map((d) => (
                    
                    <TableCell sx={{color:'white', fontWeight:'bold'}}>
                        {d}
                    </TableCell>
                    
                ))
            }
        </TableHead>
        {
            rows.map((k) => (
                <TableRow>
                    {
                        Object.values(k).map((v) => (
                            <TableCell>
                                {v}
                            </TableCell>                           
                        ))
                    }

                </TableRow>
            ))
        }
    </Table>
  </Item>
</Grid>
  )
}

export default Table8