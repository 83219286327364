import React from 'react'
import { Grid, Paper, Table, TableHead, TableRow, TableCell} from "@mui/material";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Indicator from './Indicator';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#3d3d3d',
    height:"100%",
    ...theme.typography.body2,
    padding: 3,
    textAlign: 'center',
    color: '#ffffff',
  }));

const IndicatorBlock = (props) => {
    return (
        <Grid item xs={12} md={4}>
          <Item>
            <Indicator data={props.data} goal={props.goal} title={props.title}/>
          </Item>
        </Grid>
    )
}

export default IndicatorBlock