import React from 'react'
import { Grid, Paper } from "@mui/material";
import Table4 from '../dashboard_components/Table4';
import { styled } from '@mui/material/styles'
import IndicatorBlock from '../dashboard_components/IndicatorBlock';
import { useState, useEffect } from 'react';


const Performance = () => {
  const [qSales, updateQSales] = useState({});

  useEffect(() =>{
    getQSales();
  }, []);

  const qSalesUrl = "https://xmnd4tpb48.us-west-2.awsapprunner.com/api/date_tools?year=all&type=quarterly&operation=sum&second_field=state"

  const getQSales = async () => {
    const response = await fetch(qSalesUrl);
    const jsonData = await response.json();  
    updateQSales(jsonData);
  };
  let caligoal;
  let cali;
  let flori;
  let florigoal;
  let calisales
  try{
    caligoal = 150000;
    calisales = qSales.filter((obj) => {
      if (obj.state==='California' & obj.quarter===4){
        return true
      }
      else {
        return false
      }
    })
    cali = calisales['sales']
    florigoal = 150000;
    const florisales = qSales.filter((obj) => {
      if (obj.state==='Florida' & obj.quarter===4){
        return true
      }
      else {
        return false
      }
    })
    flori = florisales.sales
    console.log(flori)
  } catch {
    console.log('getting data')
  }

  if( flori===undefined){
    flori=123000
  }

  if (cali===undefined){
    cali=197340
  }

  let tx = 136789;
  let txgoal = 130000;
  // Table stuff
  const [lSalesCa, updateLSalesCa] = useState({});

  useEffect(() =>{
    getLSalesCa();
  }, []);

  const lSalesUrl = "https://xmnd4tpb48.us-west-2.awsapprunner.com/api/date_tools?year=2017&operation=sum&type=latest&second_field=customer_name&filter_field=state&filter_value=California"

  const getLSalesCa = async () => {
    const response = await fetch(lSalesUrl);
    const jsonData = await response.json();  
    updateLSalesCa(jsonData);
  };
  try{
    console.log(lSalesCa)
  } catch {
    console.log('loading')
  }
  let latestSalesCa;

  if(lSalesCa!==undefined) {
    try{
      latestSalesCa = {
        header: [{customer_name: 'Customer', sales:'Amount'}],
        rows: lSalesCa.slice(0,10)
      }
    } catch {
      latestSalesCa = {
        header: [{customer_name: 'Customer', sales:'Amount'}],
        rows: []
      }     
    }
  } else {
    latestSalesCa = {
      header: [{customer_name: 'Customer', sales:'Amount'}],
      rows: []
    }
  }
  const [lSalesFl, updateLSalesFl] = useState({});

  useEffect(() =>{
    getLSalesFl();
  }, []);

  const lSalesUrlFl = "https://xmnd4tpb48.us-west-2.awsapprunner.com/api/date_tools?year=2017&operation=sum&type=latest&second_field=customer_name&filter_field=state&filter_val=Florida"

  const getLSalesFl = async () => {
    const response = await fetch(lSalesUrlFl);
    const jsonData = await response.json();  
    updateLSalesFl(jsonData);
  };
  try{
    console.log(lSalesFl)
  } catch {
    console.log('loading')
  }
  let latestSalesFl;

  if(lSalesFl!==undefined) {
    try{
      latestSalesFl = {
        header: [{customer_name: 'Customer', sales:'Amount'}],
        rows: lSalesFl.slice(0,10)
      }
    } catch {
      latestSalesFl = {
        header: [{customer_name: 'Customer', sales:'Amount'}],
        rows: []
      }     
    }
  } else {
    latestSalesFl = {
      header: [{customer_name: 'Customer', sales:'Amount'}],
      rows: []
    }
  }

  const [lSalesTx, updateLSalesTx] = useState({});

  useEffect(() =>{
    getLSalesTx();
  }, []);

  const lSalesUrlTx = "https://xmnd4tpb48.us-west-2.awsapprunner.com/api/date_tools?year=2017&operation=sum&type=latest&second_field=customer_name&filter_field=state&filter_val=Texas"

  const getLSalesTx = async () => {
    const response = await fetch(lSalesUrlTx);
    const jsonData = await response.json();  
    updateLSalesTx(jsonData);
  };
  try{
    console.log(lSalesTx)
  } catch {
    console.log('loading')
  }
  let latestSalesTx;

  if(lSalesFl!==undefined) {
    try{
      latestSalesTx = {
        header: [{customer_name: 'Customer', sales:'Amount'}],
        rows: lSalesTx.slice(0,10)
      }
    } catch {
      latestSalesTx = {
        header: [{customer_name: 'Customer', sales:'Amount'}],
        rows: []
      }     
    }
  } else {
    latestSalesTx = {
      header: [{customer_name: 'Customer', sales:'Amount'}],
      rows: []
    }
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{pr:2, pl:2, gridTemplateColumns:'repeat(1, 1fr)'}}>
        <IndicatorBlock data={cali} goal={caligoal} title={'California Sales Goal'}/>
        <IndicatorBlock data={flori} goal={florigoal} title={'Florida Sales Goal'}/>
        <IndicatorBlock data={tx} goal={txgoal} title={'Texas Sales Goal'}/>
      </Grid>
      <br />
      <Grid container spacing={2} sx={{pr:2, pl:2, gridTemplateColumns:'repeat(1, 1fr)'}}>
        <Table4 data={latestSalesCa} title='Latest California Sales'/>
        <Table4 data={latestSalesFl} title='Latest Florida Sales'/>
        <Table4 data={latestSalesTx} title='Latess Texas Sales'/>
      </Grid>
    </React.Fragment>
  )
}

export default Performance