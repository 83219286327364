import React from 'react'
import { Grid, Paper, Table, TableHead, TableRow, TableCell } from "@mui/material";
import Kpi from "../dashboard_components/Kpi";
import PlotBlock from "../dashboard_components/PlotBlock";
import Table4 from '../dashboard_components/Table4';
import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

const Sales = () => {


  const [ySales, updateYSales] = useState({});

  useEffect(() => {
    getySales();
  }, []);

  const ySalesUrl = "https://xmnd4tpb48.us-west-2.awsapprunner.com/api/date_tools?year=all&operation=sum&type=yearly"

  const getySales = async () => {
    const response = await fetch(ySalesUrl);
    const jsonData = await response.json();
    updateYSales(jsonData);
  };

  let x;
  let y;
  let totals;
  try {
    const cols = Object.keys(ySales[0]);
    x = ySales.map(d => d[cols[0]])
    totals = x.reduce((a, b) => a + b)
    y = ySales.map(d => d[cols[1]])
  } catch {
    x = [123];
    totals = 123.4
  }

  const [qSales, updateQSales] = useState({});

  useEffect(() => {
    getQSales();
  }, []);

  const qSalesUrl = "https://xmnd4tpb48.us-west-2.awsapprunner.com/api/date_tools?year=2017&operation=sum&type=quarterly"

  const getQSales = async () => {
    const response = await fetch(qSalesUrl);
    const jsonData = await response.json();
    updateQSales(jsonData);
  };

  let thisQ;
  try {
    thisQ = qSales[3].sales;
    if (thisQ === undefined) {
      thisQ = 100.2
    }
  } catch {
    thisQ = 123.4;
  }


  const [lSales, updateLSales] = useState({});

  useEffect(() => {
    getLSales();
  }, []);

  const lSalesUrl = "https://xmnd4tpb48.us-west-2.awsapprunner.com/api/date_tools?year=2017&operation=sum&type=latest&second_field=customer_name"

  const getLSales = async () => {
    const response = await fetch(lSalesUrl);
    const jsonData = await response.json();
    updateLSales(jsonData);
  };
  try {
    console.log(lSales)
  } catch {
    console.log('loading')
  }
  let latestSales;

  if (lSales !== undefined) {
    try {
      latestSales = {
        header: [{ customer_name: 'Customer', sales: 'Amount' }],
        rows: lSales.slice(0, 10)
      }
    } catch {
      latestSales = {
        header: [{ customer_name: 'Customer', sales: 'Amount' }],
        rows: []
      }
    }
  } else {
    latestSales = {
      header: [{ customer_name: 'Customer', sales: 'Amount' }],
      rows: []
    }
  }


  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{ pr: 2, pl: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
        <PlotBlock type='line' />
        <Grid item xs={12} md={4} sx={{ display: 'grid' }}>
          <Grid container spacing={2}>
            <Kpi name="Current Year Sales" value={`$${x[0].toFixed(2)}`} />
            <Kpi name="Sales This Quarter" value={`$${thisQ.toFixed(2)}`} />
            <Kpi name="Total Historic Sales" value={`$${totals.toFixed(2)}`} />
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2} sx={{ pr: 2, pl: 2, gridTemplateColumns: 'repeat(1, 1fr)' }}>
        <Table4 data={latestSales} />
        <PlotBlock type='bar' />
      </Grid>
    </React.Fragment>
  )
}

export default Sales