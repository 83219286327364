import { Grid, Paper, Table, TableHead, TableRow, TableCell} from "@mui/material";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#3d3d3d',
    height:"100%",
    ...theme.typography.body2,
    padding: 3,
    textAlign: 'center',
    color: '#ffffff',
  }));



const Table4 = (props) => {
  const headers = props.data.header
  const rows = props.data.rows
  return (
    <Grid item xs={12} md={4}>
    <Item>
    <h3>{props.title}</h3>
    <Table size="small">  
        {
            headers.map((d) => (
                <TableHead>
                <TableCell sx={{color:'white', fontWeight:'bold'}}>
                    {d.customer_name}
                </TableCell>
                <TableCell sx={{color:'white', fontWeight:'bold'}}>
                    {d.sales}
                </TableCell>
                </TableHead>
            ))
        }
        {
            rows.map((d) => (
                <TableRow>
                <TableCell>
                    {d.customer_name}
                </TableCell>
                <TableCell>
                    {d.sales.toFixed(2)}
                </TableCell>
                </TableRow>
            ))
        }
    </Table>
  </Item>
</Grid>
  )
}

export default Table4