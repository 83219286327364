import React from 'react'
import Plot from 'react-plotly.js'

const Indicator = (props) => {
  const reference = 100;
  const goal = props.goal;
  const data = props.data/goal*100;

  
  let color = 'red'
  if(data >= reference){
    color = 'green'
  }
    
    
  return (
    <React.Fragment>
        <Plot 
            data = {[
                {
                    value: data,
                    delta: {reference:reference},
                    gauge:{axis: {visible: true, range:[0,200]},
                           bordercolor: '#ababab',
                           bar:{
                            color: color
                        }
                    },
                    type: 'indicator', 
                    mode: 'gauge+number',

                }
            ]}

            layout={ {autosize:true,
                      title: {
                        text:props.title,
                        font:{
                            color:'#ffffff'
                        }
                        },
                      plot_bgcolor:"rgba(0,0,0,0)",
                      paper_bgcolor:"rgba(0,0,0,0)",
                      font: {color: '#ffffff'},
                      automargin:true }
                    }

            useResizeHandler= {true}

            style={{width: "100%", 
                    height: "100%", 
                    minWidth:'200px',
                    minHeight:'300px',
                    maxHeight:'300px'}} 
        />

    </React.Fragment>
  )
}

export default Indicator