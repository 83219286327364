import React from 'react'
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'

const iconButtonSides = 5;
const DrawerC = () => {
  const [openDrawer, setOpenDrawer] = React.useState(false)
  return (
    <React.Fragment>
        <Drawer open={openDrawer} 
                onClose={() => setOpenDrawer(false)}>
            <List>
                <ListItemButton to={`/demo/sales`} component={Link}>
                    <ListItemIcon>
                        <ListItemText >
                            Sales
                        </ListItemText>
                    </ListItemIcon>
                </ListItemButton>
                <ListItemButton to={`/demo/performance`} component={Link}>
                    <ListItemIcon>
                        <ListItemText >
                            Performance
                        </ListItemText>
                    </ListItemIcon>
                </ListItemButton>
                <ListItemButton to={`/demo/purchases`} component={Link}>
                    <ListItemIcon>
                        <ListItemText >
                            Purchases
                        </ListItemText>
                    </ListItemIcon>
                </ListItemButton>
                <ListItemButton sx ={{mt:'auto'}}>
                    <ListItemIcon>
                        <ListItemText>
                            Log Out
                        </ListItemText>
                    </ListItemIcon>
                </ListItemButton>
            </List>
        </Drawer>
        <IconButton sx={{
                            pl:0, 
                            ml:'0',
                            maxWidth:'30px',
                            maxHeight:'30px',  
                            display: "flex", justifyContent: "flex-end"          
                    }} 
                    onClick={() => setOpenDrawer(true)}
                    size={'small'}>
            <MenuIcon sx={{color:'#ffffff'}} />
        </IconButton>
    </React.Fragment>
  )
}

export default DrawerC