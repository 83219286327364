import React from 'react'
import Plot from 'react-plotly.js'

const PiePlot = (props) => {
  console.log(props)
  return (
    <React.Fragment>
        <Plot 
            data = {[
                {
                    values: props.data.map((d) => d.total),
                    labels: props.data.map((d) => d.provider),
                    type: 'pie', 
                    marker: {colors: ['#ffffff','#b8b8b8',"#9c9c9c", '#5c5c5c']},
                    textfont:{color:'#ffffff'},
                }
            ]}

            layout={ {autosize:true,
                      title: {
                        text: props.title,
                        font:{
                            color:'#ffffff'
                        }
                        },
                      plot_bgcolor:"rgba(0,0,0,0)",
                      paper_bgcolor:"rgba(0,0,0,0)",
                      automargin:true,
                      legend: {
                        font: {
                          color:'#fff'
                        }
                      } 
                    
                    }

                    }

            useResizeHandler= {true}

            style={{width: "100%", height: "70vh",
                    minHeight:'40vh', maxHeight:'70vh'}} 
        />

    </React.Fragment>
  )
}

export default PiePlot