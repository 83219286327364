
const Header = (props) => {
  return (
    <div className="header">
      <img src="/logo.png" alt="" id="logo"/>
    </div>
  )
}

export default Header
