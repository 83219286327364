import Navbar from "../dashboard_components/Navbar"
import Sales from "../dashboard_screens/Sales"
import Performance from "../dashboard_screens/Performance";
import Expenses from "../dashboard_screens/Expenses";
import { Grid, Paper, Table, TableHead, TableRow, TableCell} from "@mui/material";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Kpi from "../dashboard_components/Kpi";
import PlotBlock from "../dashboard_components/PlotBlock";
import * as React from 'react';
import { forwardRef } from 'react'
import { AppBar, Toolbar, Box, Typography, Tabs, Tab } from '@mui/material'
import DrawerC from '../dashboard_components/DrawerC';
import { useMediaQuery , useTheme } from '@mui/material';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import { useImperativeHandle } from 'react';
import { Link } from 'react-router-dom';
import {Switch, UNSAFE_DataRouterStateContext} from 'react-router'
import Purchases from "../dashboard_screens/Purchases";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#3d3d3d',
  height:"100%",
  ...theme.typography.body2,
  padding: 3,
  textAlign: 'center',
  color: '#ffffff',
}));

const mdTheme = createTheme({
  palette: {
    primary: {
      main:'#1d1d1d',
    }
  }
});

const Demo = (props) => {
  if(props.where==='sales'){
  return (
    <ThemeProvider theme={mdTheme}>
      {/* Navbar */}
      <Navbar />
      <Sales />
    </ThemeProvider>
  )
} else if(props.where==='performance'){
  return(
    <ThemeProvider theme={mdTheme}>
    {/* Navbar */}
    <Navbar />
    <Performance />
    </ThemeProvider>   
  )
} else if(props.where==='expenses'){
  return(
    <ThemeProvider theme={mdTheme}>
    {/* Navbar */}
    <Navbar />
      <Expenses />
    </ThemeProvider>   
  )  
} else if(props.where==='purchases'){
  return(
    <ThemeProvider theme={mdTheme}>
    {/* Navbar */}
    <Navbar />
      <Purchases />
    </ThemeProvider>   
  )  

}

  else {
  return(
    <ThemeProvider theme={mdTheme}>
    {/* Navbar */}
    <Navbar />
    it is likely you broke something, please click any button in navbar
    </ThemeProvider>  
  )
}
}
export default Demo