import React from 'react'
import { Grid, Paper, Table, TableHead, TableRow, TableCell } from "@mui/material";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#3d3d3d',
  height: "100%",
  ...theme.typography.body2,
  padding: 3,
  textAlign: 'center',
  color: '#ffffff',
}));

const Kpi = (props) => {
  return (
    <Grid item xs={12} md={12}>
      <Item>
        <h3>{props.name}</h3>
        <h1>{props.value}</h1>
      </Item>
    </Grid>
  )
}

export default Kpi