import {useEffect} from 'react';
import Header from '../components/Header';
import Button from '../components/Button';

const Home = () => {

  useEffect(() => {
    window.addEventListener('scroll', reveal)
    function reveal() {
      let el = document.querySelectorAll('.reveal');
      for(let i = 0; i< el.length; i++){
        let windowHeight = window.innerHeight;
        var revealtop = el[i].getBoundingClientRect().top;
        var revealpoint = 150;

        if(revealtop < windowHeight - revealpoint){
          el[i].classList.add('active');
        }
        else {
          el[i].classList.remove('active');
        }
      }
    }
  }, []);

  return (
    <div className="container">
      <section id='logoSection'>
        <div className='container'>
          <Header />
        </div>
      </section>
{/* plotspace to be filled */}
      <section className='textSection'>
        <div className='textDiv reveal'>
          <h2>
            ¿Quiénes somos?
          </h2>
          <p>
          En Black Box nos dedicamos a brindar soluciones tecnológicas a su medida para optimizar procesos y mejorar la toma de decisiones en las empresas.           </p>
          <p>
          Sabemos que los datos son fundamentales para todo negocio, y por eso complementamos nuestras soluciones basadas en inteligencia de negocios, análisis de datos, machine learning e inteligencia artificial, con un sistema de consultoría y acompañamiento para hacer tus soluciones únicas y personalizadas.          </p>
        </div>

      </section>
      <section className='textSection'>
        <div className='textDiv reveal'>
          <h2>Nuestra Misión</h2>
          <p>
          Impulsar el desarrollo tecnológico para optimizar la toma de decisiones en empresas locales e internacionales, facilitando el acceso a la información a través de tecnologías de nueva generación.
          </p>
        </div>        
      </section>
      <section className='textSection'>
        <div className='textDiv2 reveal'>
          <h2>Nuestros servicios</h2>
          <p>Todos nuestros servicios son a la medida, se ajustan a las necesidades de tu empresa y están diseñados de forma personalizada de modo que puedas tomar las mejores decisiones desde cualquier dispositivo. </p>
          <div className='cards'>
            <div className='textCard'>
                <h4>Arquitectura de datos</h4>
                <p className='cardP'>
                Utilizamos tecnologías en la nube para automatizar el manejo y almacenamiento de tus datos, facilitando el acceso y garantizando integridad.
                </p>
            </div>
            <div className='textCard'>
                <h4>Análisis e indicadores</h4>
                <p className='cardP'>
                Diseñamos indicadores para medir el desempeño de tu empresa y ofrecemos servicio de reportería. Tú sólo te preocupas por tomar decisiones.
                </p>
            </div>
            <div className='textCard'>
                <h4>Machine Learning</h4>
                <p className='cardP'>
                Modelos matemáticos a la medida. Pronostica tus ventas, optimiza tu marketing, optimiza inventarios, etc., utilizando lo último en tecnología de inteligencia artificial.
                </p>
            </div>
            <div className='textCard'>
                <h4>Tableros Interactivos</h4>
                <p className='cardP'>
                Inteligencia de negocios 100% personalizable en la nube a un sólo clic de distancia. Al detalle y jerarquía que sean necesarios. 
                </p>
            </div>
            <div className='textCard'>
                <h4>Software Empresarial</h4>
                <p className='cardP'>
                Soluciones para tu manejo de inventarios, ventas, compras y cotizaciones, conectadas a tu base de datos.
                </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='textDiv reveal'>
          <h2>¡Hay un mundo fuera de la caja!</h2>
          
          <div className='buttons'>
            <Button whereTo='demo'/>
            <Button whereTo='contact'/>

          </div>

        </div>
      </section>
      
    </div>
  )
}

export default Home
